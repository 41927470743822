<template>
    <loader-text RouteText="Welcome" />
    <header-component :temperature="weatherTemperature"/>
    <div class="content-container h-full pt-[110px] flex flex-col justify-center items-center lg:flex-row lg:justify-between">
        <animation-component :temperature="weatherTemperature" />
        <content-component />
    </div>
    <footer-component />
</template> 

<script >

import LoaderText  from "../components/loadertext.vue"; 
import HeaderComponent from "../components/header.vue";
import AnimationComponent from "../components/homepage/fullstackanimation.vue";
import ContentComponent from "../components/homepage/content.vue";
import FooterComponent from "../components/footer.vue";

    export default {
        name: 'homepage',
        components: {
            LoaderText,
            HeaderComponent,
            AnimationComponent,
            ContentComponent, 
            FooterComponent,
        },
        data() {
            return {
                weatherTemperature: window.weatherTemperature,
            };
        },
    }
</script> 