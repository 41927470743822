<template>
 
        
        <loader-text RouteText="About-me" />
        <header-component :temperature="weatherTemperature"/>
        <div class="about w-[90%] mx-auto pt-12">
            <div class="about-content max-w-[900px] mx-auto">
                <biography /> 
            </div>
        </div>

        <div class="about w-[90%] mx-auto py-12 ">
            <div class="max-w-[900px] mx-auto">
                <projects />
            </div>
        </div>
        <footer-component />
</template>


<script>
import LoaderText  from "../components/loadertext.vue"; 
import HeaderComponent from "../components/header.vue";
import Biography from "../components/about/biography.vue";
import MyJourney from "../components/about/myjourney.vue";
import Projects from "../components/about/projects.vue";
import FooterComponent from "../components/footer.vue";


export default {
        name: 'about',
        components: {
            LoaderText,
            HeaderComponent,
            Biography,
            Projects,
            MyJourney,
            FooterComponent,
        },
        data() {
            return {
                weatherTemperature: window.weatherTemperature,
            };
        },
    }

</script>

<style scoped>
</style>