import { createApp, compile } from 'vue';
import App from './vue/pages/homepage.vue';
import About from './vue/pages/about.vue';


/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './scss/app.scss';


createApp(App).mount('#app'); 
createApp(About).mount('#about'); 

